import React from "react";
import DreamLogo from "../Logos/Dream Abpco.png";

function About() {
  return (
    <div className="h-full flex flex-col font-Wallika">
      <div className=" p-5 flex flex-row items-center justify-center">
        <div
          className="h-[30rem] w-[65rem] rounded-lg grid lg:grid-cols-[40%_60%] grid-cols-2
        md:grid-cols-2"
        >
          <div className="flex items-center justify-center">
            <img
              className="object-cover h-[15rem] w-[18rem] m-5 rounded-2xl"
              src={DreamLogo}
              alt=""
            />{" "}
          </div>
          <div className="bg-white p-2 items-center justify-center flex flex-col">
            <div className="text-center text-[40px] p-2 font-semibold">
              About Us
            </div>
            <div className="text-[25px] font-medium">Dream ABPCO</div>
            <div className="text-center text-[16px] lg:text-[25px] font-medium text-purple-800">
              We believe that working together as teamwork and doing thing right
              from the first time!
            </div>
            <div className="text-[18px] font-normal p-5 text-gray-700">
              Even though ABPCO was established in the year 2019 in Ethiopia,
              the leadership team comes with a 30 Year experience in Automotive
              business (Lubricates, Tire, Battery, Manufacturing), and related
              business overseas. Throughout this period, our business was
              building trusted relationships and high standards with our
              suppliers and our Clients
            </div>
          </div>
        </div>
      </div>
      <div className="p-5 flex flex-row items-center justify-center">
        <div>
          <div className=" lg:visible md:visible invisible"></div>
          <div className="bg-white p-2 items-center justify-center flex flex-col">
            <div className="text-[36px] font-semibold">Dream ABPCO</div>
            <div className="text-center text-[32px] font-medium text-purple-800">
              30 Years of Successes
            </div>
            <div className="text-[16px] font-normal p-5 text-gray-700 lg:pl-32 lg:pr-32">
              Our Company was founded in June 2019 in Ethiopia. ,and in overseas
              , is Nearing 30 Years of our experience in Automotive business ,
              Lubricates , tire , Battery , Manufacturing and others business as
              Employee or as Owner , We build good relation and high expectation
              with our suppliers and our Clients. Throughout our history, we
              have remained true to our core values of integrity, excellence,
              and customer satisfaction, and we have always strived to
              contribute to the economic and social development of Ethiopia and
              the communities we serve.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
