import React from "react";
import ErrorPic from "../img/404.png";

function Error() {
  return (
    <div className="text-red-500 font-bold  text-center flex justify-center flex-col items-center m-5">
      <div className="font-semibold text-5xl">Sorry for the Confusion</div>
      <div className="font-medium">
        the page you're looking for doesnot exist or is not working
      </div>

      <div className="w-[25rem] h-[25rem]">
        <img src={ErrorPic} />
      </div>
    </div>
  );
}

export default Error;
