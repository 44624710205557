import React from "react";
import Container from "../Background.jpg";
import Navbar from "./Navbar";

function HomePage() {
  return (
    <div className="h-full">
      <div
        className="text-white bg-cover bg-center font-Roboto"
        style={{
          backgroundImage: `url(${Container})`,
        }}
      >
        <Navbar/>
        <div className="max-w-[800px] w-full mx-auto flex flex-col lg:p-32 md:p-24 p-10 ">
          <div className="lg:text-[90px] md:text-[60px] text-[45px] font-bold -mb-10">
            Dream 
          </div><div className="lg:text-[90px] md:text-[60px] text-[45px] font-bold">
          ABPCO 
          </div>
          <div className="lg:text-[40px] md:text-[30px] sm:text-[18px] text-[18px] font-normal">
            we make it happen
          </div>
          <div className="text-[20px] text-pretty font-medium">
            We believe that working together as teamwork and doing thing right
            from the first time !
          </div>
          <a href={"./about"}>
            <div className="text-[#1e1e1e] w-48 h-10 bg-white flex flex-col items-center justify-center rounded-xl font-semibold mt-5">
              Learn more
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
