import React, { useState } from "react";

function FAQ() {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="h-full bg-white  flex flex-col items-center justify-center font-Wallika font-light">
      <div className="flex flex-col">
        <div className="text-[20px] text-center font-medium">how we work</div>
        <div className="p-5 bg-indigo-50 rounded-md text-[16px] m-5 font-light">
          <div>
            1 .The moment a customer contact us, our priority and responsibility
            is our customer satisfaction.
          </div>
          <div>
            2 .It is our goal to build closer relationships and to build win -
            win relationships with our customers
          </div>
          <div>3 .Deal with our customers as partners.</div>
          <div>
            4 .We work together with our partner as one team and to do the best
            business together with a long term and Short-term plan
          </div>
        </div>

        <div className="text-[20px] font-medium text-center">
          Frequently asked Question
        </div>
        <div className="text-center font-medium text-gray-600 p-2">
          Explore our frequently asked questions to find information on how to
          we work, how to reach us and more
        </div>
        <div></div>
        <div></div>
      </div>
      <div className="p-2">
        <div className="max-w-3xl mx-auto m-2 p-4 rounded-xl bg-blue-50 border border-gray-300 mb-5">
          <div className="flex flex-row items-center justify-between">
            <h2 className="text-[1.4rem] font-medium ">
              What kind of products and services do you offer?
            </h2>
          </div>
          <div className={`mt-4 ${expanded ? "block" : "block"}`}>
            <p className="text-gray-700 text-[1rem]">
              We offer a variety of products and services, from raw materials to
              finished goods, from machinery to consumer products, from
              agriculture to technology. Whatever you need to import or export,
              we can source it for you at the best
            </p>
            {/* Additional text to be displayed when expanded */}
            {expanded && (
              <p className="mt-2 text-gray-700">
                More information or detailed answer here...
              </p>
            )}
          </div>
        </div>
        <div className="max-w-3xl mx-auto m-2 p-4 bg-blue-50 rounded-xl border border-gray-300">
          <div className="flex flex-row items-center justify-between">
            <h2 className="text-[1.4rem] font-medium ">
              How can I contact you?
            </h2>
          </div>
          <div className={`mt-4 ${expanded ? "block" : "block"}`}>
            <p className="text-gray-700 text-[1rem] p-1">
              1 .You can contact us in various ways, depending on your
              preference and convenience. You can contact us with the above
              contact links provided
            </p>
            <p className="text-gray-700 text-[1rem] p-1">
              2 .Send us an{" "}
              <span className="font-semibold">
                email at abahammam@yahoo.com
              </span>{" "}
              or
              <span className="font-semibold">abahammam@dreamapbco.com </span>
              and we will reply you as soon as possible
            </p>
            <p className="text-gray-700 text-[1rem] p-2 font-medium">
              We are always happy to hear from you and to assist you with your
              import and export needs. Please feel free to contact us anytime
              and we will do our best to serve you
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
