import React from "react";
import Blackeyedbeans from "../img/exports/Beans/Blackeyedbeans.jpeg";
import Chickpeas from "../img/exports/Beans/Chickpeas.jpg";
import Kidneybeans from "../img/exports/Beans/Kidneybeans.png";
import Navybeans from "../img/exports/Beans/Navybeans.jpg";
import Redbeans from "../img/exports/Beans/Redbeans.jpg";
import Soybeans from "../img/exports/Beans/Soybeans.jpg";

import GreenCoffeeBean from "../img/exports/Coffee/coffee.jpg";
import Coffee_3 from "../img/exports/Coffee/Coffee_3.jpg";
import Coffee_4 from "../img/exports/Coffee/Coffee_4.jpg";

import Basil from "../img/exports/Herbs/Basil.jpg";
import Bayleafs from "../img/exports/Herbs/Bayleafs.jpg";
import Dill from "../img/exports/Herbs/Dill-.png";
import Mint from "../img/exports/Herbs/Mint.jpg";
import Rosemary from "../img/exports/Herbs/Rosemary.jpg";
import Thyme from "../img/exports/Herbs/Thyme.jpg";

import Chia from "../img/exports/Oilseeds/Chia.jpg";
import Hemps from "../img/exports/Oilseeds/Hemps.jpg";
import Peanuts from "../img/exports/Oilseeds/Peanuts.jpg";
import Sesame from "../img/exports/Oilseeds/Sesame.jpg";
import Sunflower from "../img/exports/Oilseeds/Sunflower.jpg";
import Flax from "../img/exports/Oilseeds/Flax.jpg";

import Bengalgram from "../img/exports/Pulses/Bengalgram.jpg";
import Blackgram from "../img/exports/Pulses/Blackgram.jpg";
import GreenPeas from "../img/exports/Pulses/GreenPeas.jpg";
import Greengram from "../img/exports/Pulses/Greengram.jpg";
import Lentils from "../img/exports/Pulses/Lentils.jpg";

import Blackpepper from "../img/exports/Spices/Blackpepper.png";
import Cardamom from "../img/exports/Spices/Cardamom.png";
import Clove from "../img/exports/Spices/Clove.jpg";
import Cuminseeds from "../img/exports/Spices/Cuminseeds.jpg";
import Ginger from "../img/exports/Spices/Ginger.jpg";
import Turmeric from "../img/exports/Spices/Turmeric.jpg";

function ExportDetails() {
  return (
    <div className="h-full font-Wallika">
      {/* one section here */}
      <div className="p-5 ">
        <div className="text-[20px] font-medium text-center bg-gray-200 text-[#3652AD]">
          Beans
        </div>
        <div className="flex flex-wrap gap-3 m-5 items-center justify-center">
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Blackeyedbeans} alt="" />
            <div className=" text-xl  font-semibold text-center font-semibold text-center">Blackeyedbeans</div>
            <div className="text-md w-64">
              Introduce a hearty and nutty flavor to your recipes with
              black-eyed beans.
            </div>
          </div>
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Soybeans} alt="" />
            <div className=" text-xl  font-semibold text-center">Soybeans</div>
            <div className="text-md w-64">
              Experience the versatile and protein-packed goodness of soybeans.
            </div>
          </div>
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Chickpeas} alt="" />
            <div className=" text-xl  font-semibold text-center">Chickpeas</div>
            <div className="text-md w-64">
              Embrace the nutty and robust taste of chickpeas, a staple in many
              cuisines.
            </div>
          </div>
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Kidneybeans} alt="" />
            <div className=" text-xl  font-semibold text-center">Kidneybeans</div>
            <div className="text-md w-64">
              Enhance the heartiness of your dishes with the rich and meaty
              texture of kidney beans.
            </div>
          </div>
          <div className=" border border-black p-2 m-2 rounded-lg h-72">
            <img className="w-60 h-48 rounded-md" src={Navybeans} alt="" />
            <div className=" text-xl  font-semibold text-center">Navybeans</div>
            <div className="text-md w-64">
              Enjoy the mild and creamy taste of navy beans in soups, stews, and
              casseroles.
            </div>
          </div>
          <div className=" border border-black p-2 m-2 rounded-lg h-72">
            <img className="w-60 h-48 rounded-md" src={Redbeans} alt="" />
            <div className=" text-xl  font-semibold text-center">Redbeans</div>
            <div className="text-md w-64">
              Add a vibrant burst of color and flavor to your meals with red
              beans.
            </div>
          </div>
        </div>
      </div>
      {/* the section ends here */}

      {/* one section here */}
      <div className="p-5">
        <div className="text-[20px] font-medium text-center bg-gray-200 text-[#3652AD]">
          Spices
        </div>
        <div className="flex flex-wrap gap-3 m-5 items-center justify-center">
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Greengram} alt="" />
            <div className=" text-xl  font-semibold text-center">Greengram</div>
            <div className="text-md w-64">
              Delight in the nutty and earthy flavor of green grams, a versatile
              legume
            </div>
          </div>
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Lentils} alt="" />
            <div className=" text-xl  font-semibold text-center">Lentils</div>
            <div className="text-md w-64">
              Add a protein-packed punch to your meals with the diverse range of
              lentils.
            </div>
          </div>
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Bengalgram} alt="" />
            <div className=" text-xl  font-semibold text-center">Bengalgram</div>
            <div className="text-md w-64">
              Enjoy the nutty and slightly sweet taste of Bengal grams in your
              cooking.
            </div>
          </div>
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Blackgram} alt="" />
            <div className=" text-xl  font-semibold text-center">Blackgram</div>
            <div className="text-md w-64">
              Explore the rich and creamy texture of black gram, perfect for
              curries and stews.
            </div>
          </div>
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={GreenPeas} alt="" />
            <div className=" text-xl  font-semibold text-center">GreenPeas</div>
            <div className="text-md w-64">
              Brighten up your dishes with the sweet and vibrant taste of green
              peas.
            </div>
          </div>
        </div>
      </div>
      {/* the section ends here */}
      {/* one section here */}
      <div className="p-5">
        <div className="text-[20px] font-medium text-center bg-gray-200 text-[#3652AD]">
          Herbs
        </div>
        <div className="flex flex-wrap gap-3 m-5 items-center justify-center">
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Mint} alt="" />
            <div className=" text-xl  font-semibold text-center">Mint</div>
            <div className="text-md w-64">
              Refresh your palate with the cool and crisp taste of mint.
            </div>
          </div>
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Thyme} alt="" />
            <div className=" text-xl  font-semibold text-center">Thyme</div>
            <div className="text-md w-64">
              Enhance the savory profile of your dishes with the aromatic touch
              of thyme
            </div>
          </div>
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Bayleafs} alt="" />
            <div className=" text-xl  font-semibold text-center">Bayleafs</div>
            <div className="text-md w-64">
              Infuse depth and complexity into your culinary creations with the
              subtle aroma of
            </div>
          </div>
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Basil} alt="" />
            <div className=" text-xl  font-semibold text-center">Basil</div>
            <div className="text-md w-64">
              Elevate your dishes with the sweet and aromatic essence of basil
            </div>
          </div>
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Rosemary} alt="" />
            <div className=" text-xl  font-semibold text-center">Rosemary</div>
            <div className="text-md w-64">
              Immerse your recipes in the robust and pine-like flavor of
              rosemary
            </div>
          </div>
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Dill} alt="" />
            <div className=" text-xl  font-semibold text-center">Dill</div>
            <div className="text-md w-64">
              Experience the light and feathery taste of dill in your culinary
              adventures.
            </div>
          </div>
        </div>
      </div>
      {/* the section ends here */}
      {/* one section here */}
      <div className="p-5">
        <div className="text-[20px] font-medium text-center bg-gray-200 text-[#3652AD]">
          Spices
        </div>
        <div className="flex flex-wrap gap-3 m-5 items-center justify-center">
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Clove} alt="" />
            <div className=" text-xl  font-semibold text-center">Clove</div>
            <div className=" text-md w-64">
              Add a touch of warmth and depth to your dishes with the aromatic
              essence of clove.
            </div>
          </div>
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Blackpepper} alt="" />
            <div className=" text-xl  font-semibold text-center">Blackpepper</div>
            <div className=" text-md w-64">
              Elevate your cuisine with the bold, spicy kick of black pepper
            </div>
          </div>
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Cardamom} alt="" />
            <div className=" text-xl  font-semibold text-center">Cardamom</div>
            <div className=" text-md w-64">
              Indulge in the sweet and floral notes of cardamom for a unique
              flavor experience
            </div>
          </div>
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Cuminseeds} alt="" />
            <div className=" text-xl  font-semibold text-center">Cuminseeds</div>
            <div className=" text-md w-64">
              Transform your recipes with the earthy and slightly nutty taste of
              cumin seeds
            </div>
          </div>
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Ginger} alt="" />
            <div className=" text-xl  font-semibold text-center">Ginger</div>
            <div className=" text-md w-64">
              Infuse your meals with the zesty and pungent flavor of ginger
            </div>
          </div>
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Turmeric} alt="" />
            <div className=" text-xl  font-semibold text-center">Turmeric</div>
            <div className=" text-md w-64">
              Embrace the golden hue and anti-inflammatory properties of
              turmeric in your cooking
            </div>
          </div>
        </div>
      </div>
      {/* the section ends here */}
      {/* one section here */}
      <div className="p-5">
        <div className="text-[20px] font-medium text-center bg-gray-200 text-[#3652AD]">
          Oilseeds
        </div>
        <div className="flex flex-wrap gap-3 m-5 items-center justify-center">
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Flax} alt="" />
            <div className=" text-xl  font-semibold text-center">Flax</div>
            <div className="w-60 text-md">
              Discover the nutritional powerhouse of flax seeds.
            </div>
          </div>
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Sesame} alt="" />
            <div className=" text-xl  font-semibold text-center">Sesame</div>
            <div className="w-60 text-md">
              Elevate your culinary experience with sesame seeds.
            </div>
          </div>
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Chia} alt="" />
            <div className=" text-xl  font-semibold text-center">Chia</div>
            <div className="w-60 text-md ">
              Unleash the potential of chia seeds, a superfood that delivers a
              nutrient punch.
            </div>
          </div>
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Sunflower} alt="" />
            <div className=" text-xl  font-semibold text-center">Sunflower</div>
            <div className="w-60 text-md">
              Snack smart with sunflower seeds! Bursting with essential
              nutrients.
            </div>
          </div>
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Hemps} alt="" />
            <div className=" text-xl  font-semibold text-center">Hemp</div>
            <div className="w-60 text-md">
              Embrace the nutritional benefits of hemp seeds.
            </div>
          </div>
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Peanuts} alt="" />
            <div className=" text-xl  font-semibold text-center">Peanut</div>
            <div className="w-60 text-md">
              Enjoy the classic goodness of peanuts roasted or raw.
            </div>
          </div>
        </div>
      </div>
      {/* the section ends here */}
      {/* one section here */}
      <div className="p-5">
        <div className="text-[20px] font-medium text-center bg-gray-200 text-[#3652AD]">
          Coffee
        </div>
        <div className="flex flex-wrap gap-3 m-5 justify-center items-center">
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img
              className="w-60 h-48 rounded-md"
              src={GreenCoffeeBean}
              alt=""
            />
            <div className=" text-xl  font-semibold text-center">Coffee Beans</div>
            <div className=" text-md w-60">
              We provide the best coffee beans from all coffee regions in
              Ethiopia
            </div>
          </div>
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Coffee_3} alt="" />
            <div className=" text-xl  font-semibold text-center">Peanut</div>
            <div className="w-60 text-md">
              Enjoy the classic goodness of peanuts roasted or raw.
            </div>
          </div>
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Coffee_4} alt="" />
            <div className=" text-xl  font-semibold text-center">Peanut</div>
            <div className="w-60 text-md">
              Enjoy the classic goodness of peanuts roasted or raw.
            </div>
          </div>
        </div>
      </div>
      {/* the section ends here */}
    </div>
  );
}

export default ExportDetails;
