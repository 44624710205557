import React from "react";
import Person1 from "../img/peoples/person_1.jpg";
import Person2 from "../img/peoples/person_2.jpg";
import Person3 from "../img/peoples/person_3.jpg";
// this is a commnet
function Teams() {
  return (
    <div className=" h-full flex flex-col lg:pt-20  font-Wallika">
      <div className="text-center text-[22px] font-normal bg-slate-200 m-2">
        Teams
      </div>
      <div className="text-center text-[16px] font-normal">
        We value our teams and all our employees
      </div>

      <div className="mx-auto flex flex-wrap">
        <div className="flex flex-wrap gap-x-2 justify-center gap-y-2">
          <div className="overflow-hidden">
            <img
              className="object-cover h-[15rem] w-[15rem] m-5 rounded-full"
              src={Person1}
              alt=""
            />
            <div className="text-center rounded-br-xl rounded-bl-xl">
              <div className="font-normal text-[17px]">Ameen Saeed Ali</div>
              <div className="text-[14px]">CEO</div>
            </div>
          </div>
          <div className="">
            <img
              className="object-cover h-[15rem] w-[15rem] m-5 rounded-full"
              src={Person2}
              alt=""
            />
            <div className="text-center rounded-br-xl rounded-bl-xl">
              <div className="font-normal text-[17px]">Nedah Salah Saeed</div>
              <div className="text-[14px]">PR</div>
            </div>
          </div>
          <div className="">
            <img
              className="object-cover h-[15rem] w-[15rem] m-5 rounded-full"
              src={Person3}
              alt=""
            />
            <div className="text-center rounded-br-xl rounded-bl-xl">
              <div className="font-normal text-[17px]">Mohammed Saeed Ali</div>
              <div className="text-[15px]">Manager</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Teams;
