import React from "react";

function CTA() {
  return (
    <div className="h-full p-10  flex flex-col bg-blue-50 m-2 items-center justify-center  font-Wallika">
      <div className="flex flex-col justify-center lg:m-5 md:m-5 text-center items-center">
        <div className="lg:text-[20px] text-[20px] font-medium">
          For more information and to work with us
        </div>

        <div className="grid grid-cols-1 h-[8 rem] max-w-3xl">
          <div className="">
            <div className="text-[16px] font-normal m-2">
              Don't waste time, be part of the family
            </div>
            <div className="bg-[#3e4b4a] h-10 m-5 flex justify-center items-center text-white rounded-lg font-semibold">
              <a href={"/contact"}>Contact Us</a>
            </div>
          </div>
        </div>
        <div>{/* <FAQ /> */}</div>
      </div>
    </div>
  );
}

export default CTA;
