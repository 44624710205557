import React from "react";
import Error from "./pages/Error";
import About from "./pages/About";
import Contact from "./pages/Contact";
import ExportDetail from "./pages/ExportDetails";
import Choose from "./pages/Choose";
import Stats from "./pages/statistics";
import Mission from "./pages/Mission";
import Export from "./pages/ExportSection";
import ImportSection from "./pages/ImportSection";
import Footer from "./pages/Footer";
import CTA from "./pages/cta";
import Home from "./pages/HomePage";
import Teams from "./pages/Teams";
import Sisters from "./pages/Sister_companies";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import FAQ from "./pages/FAQ";
import SlideShow from "./pages/SlideShow";
import ImportDetail from "./pages/ImportDetail";

import { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

function Main() {
  const homeRef = useRef(null);
  const chooseRef = useRef(null);
  const slideShowRef = useRef(null);
  const sistersRef = useRef(null);
  const statsRef = useRef(null);
  const missionRef = useRef(null);
  const exportRef = useRef(null);
  const importSectionRef = useRef(null);
  const teamsRef = useRef(null);
  const ctaRef = useRef(null);
  const faqRef = useRef(null);
  const footerRef = useRef(null);

    useEffect(() => {
      const animateSection = (ref) => {
        gsap.to(ref.current, {
          y: -100,
          duration: 1.4,
          delay: 0.4,
          scrollTrigger: {
            trigger: ref.current,
            start: 'top center',
            end: 'bottom center',
            scrub: true,
            scrub: 0.5
          },
        });
      };

    animateSection(homeRef);
    animateSection(chooseRef);
    animateSection(slideShowRef);
    animateSection(sistersRef);
    animateSection(statsRef);
    animateSection(missionRef);
    animateSection(exportRef);
    animateSection(importSectionRef);
    animateSection(teamsRef);
    animateSection(ctaRef);
    animateSection(faqRef);
    animateSection(footerRef);
  }, []);

  

  return (
    <div>
      <div className="w-full">
        <div>
          <Home />
        </div>
        <div ref={chooseRef}>
          <Choose />
        </div>
        <div ref={slideShowRef}>
          <SlideShow />
        </div>
        <div ref={sistersRef}>
          <Sisters />
        </div>
        <div ref={statsRef}>
          <Stats />
        </div>
        <div ref={missionRef}>
          <Mission />
        </div>
        <div ref={exportRef}>
          <Export />
        </div>
        <div ref={importSectionRef}>
          <ImportSection />
        </div>
        <div ref={teamsRef}>
          <Teams />
        </div>
        <div ref={ctaRef}>
          <CTA />
        </div>
        <div ref={faqRef}>
          <FAQ />
        </div>
        <div ref={footerRef}>
          <Footer />
        </div>
      </div>
    </div>
  );
}

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Main />,
      errorElement: <Error />,
    },
    {
      path: "/about",
      element: <About />,
      errorElement: <Error />,
    },
    {
      path: "/contact",
      element: <Contact />,
      errorElement: <Error />,
    },
    {
      path: "/export",
      element: <ExportDetail />,
      errorElement: <Error />,
    },
    {
      path: "/import",
      element: <ImportDetail />,
      errorElement: <Error />,
    },
    {
      path: "/faq",
      element: <FAQ />,
      errorElement: <Error />,
    },
  ]);
  return <RouterProvider router={router} />;
}

export default App;
