import React from "react";
import Missin from "../icons/mission.png"
import Vision from "../icons/vision.png";
import Value from "../icons/values.png";


export default function Mission() {
  return (
    <div className="h-full flex flex-col justify-center items-center font-Wallika ">
      <div className="text-2xl">Core Values that represnt our company</div>
      <div className="font-normal text-sm m-2 text-center lg:text-[14px] md:text-[18px]">
        Our company lives and operates with core values that our managers and
        employees live with
      </div>
      <div className="flex flex-wrap justify-center items-center">
        <div className="h-[10rem] w-[16rem] rounded-2xl m-5 flex flex-col items-center bg-blue-50/50 border border-gray-300 text-center justify-center">
        <img className="w-12" src={Vision} />
          <div className="font-medium text-xl text-blue-800">Vision</div>
          <div className="font-normal text-[14px] bg-blue-0 p-3 w-[16rem]">
            To become leading trading & Service Company
          </div>
        </div>
        <div className="h-[10rem] w-[16rem] rounded-2xl m-5 flex flex-col items-center bg-blue-50/50 border border-gray-300  justify-center">
        <img className="w-10" src={Missin} />
          <div className="font-medium text-xl text-blue-800">Mission</div>
          <div className="font-normal text-[14px] bg-blue-0 p-3 w-[16rem] text-center">
            To provide quality products and professional services to our
            customers.
          </div>
        </div>
        <div className="h-[10rem] w-[16rem] rounded-2xl m-5 flex flex-col items-center bg-blue-50/50 border border-gray-300  justify-center">
        <img className="w-12" src={Value} />
          <div className="font-medium text-xl text-blue-800">Core Value</div>
          <div className="font-light text-[14px] bg-blue-0 p-3 w-[16rem] text-center">
            Integrity, Excellence, Teamwork and Customer satisfaction
          </div>
        </div>
      </div>
    </div>
  );
}
