import React from "react";
import CEPSA from "../Logos/CEPSA.png";
import Abpco from "../Logos/Abpco.png";
import Dream_motors from "../Logos/Dream_motors.png";
import IMEX from "../Logos/IMEX.png";
import Insharo from "../Logos/Insharo.png";

function Sister_companies() {
  return (
    <div className=" text-center  font-Wallika">
      <div className="text-[18px]">
        Our Sister Companies
      </div>
      <div className="flex flex-wrap gap-20 justify-center items-center ">
        <a href="">
          <img className="w-36" src={Dream_motors} />
          {/* <div className="text-sm">Dream motors</div> */}
        </a>

        <a href="https://imex-internationaltrading.com">
          <img className="w-28" src={IMEX} />
          {/* <div  className="text-base">IMEX International Trading</div> */}
        </a>
        <a href="https://insharo.consulting">
          <img className="w-28" src={Insharo} />
          {/* <div  className="text-base">Basha Consulting or insharo</div> */}
        </a>
        <a href="https://www.cepsa.com">
          <img className="w-28" src={CEPSA} />
          {/* <div  className="text-base">CEPSA</div> */}
        </a>
      </div>
    </div>
  );
}

export default Sister_companies;
