import React from "react";

function Stats() {
  return (
    <div className="flex items-center justify-center h-full font-Wallika">
      <div className="p-5 rounded-xl ">
        <div className="text-[20px] font-normal bg-gradient-to-l text-center ">
          Our statistics
        </div>
        <div className="text-center text-[16px] font-light">
          our numbers will tell you more aobut our deeds than our words
        </div>
        <div className="flex lg:flex-row md:flex-row mx-auto gap-x-5 flex-col justify-center  items-center lg:gap-x-5 md:gap-x-5 gap-y-5 md:m-10  pb-5">
          <div className="flex flex-col bg-blue-50/50 h-52 w-[14rem] p-5 rounded-xl border border-gray-300 text-center justify-center">
            <div className="text-[25px] font-normal">30+</div>
            <div className="text-[16px]">clients</div>
            <div className="text-[14px] text-gray-600 font-normal">
              loyal clients that we have made
            </div>
          </div>
          <div className="flex flex-col bg-blue-50/50 h-52 w-[14rem] p-5 rounded-xl border border-gray-300 text-center justify-center">
            <div className="text-[25px] font-normal">7+</div>
            <div className="text-[16px]">sectors</div>
            <div className="text-[14px] text-gray-600 font-normal">
              loyal clients that we have made
            </div>
          </div>
          <div className="flex flex-col bg-blue-50/50 h-52 w-[14rem] p-5 rounded-xl border border-gray-300 text-center justify-center">
            <div className="text-[25px] font-normal">20+</div>
            <div className="text-[16px]">years of experience</div>
            <div className="text-[14px] text-gray-600 font-normal">
              loyal clients that we have made
            </div>
          </div>
          <div className="flex flex-col bg-blue-50/50 h-52 w-[14rem] p-5 rounded-xl border border-gray-300 text-center justify-center">
            <div className="text-[25px] font-normal">100s</div>
            <div className="text-[16px]">of employees</div>
            <div className="text-[14px] text-gray-600 font-normal">
              loyal clients that we have made
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stats;
