import React from "react";
import Facebook from "../img/Facebook.png";
import Telegram from "../img/Telegram.png";
import Instagram from "../img/Instagram.png";
import Tiktok from "../img/Tiktok.png";

function Contact() {
  return (
    <div className="flex items-center justify-center font-Wallika h-screen bg-black/10  flex-col">
      <div className="text-[16px] text-gray-600">
        Dream abpco import and export group
      </div>
      <div className="text-[45px] font-light text-center">Contact Us</div>
      <div className="text-xl font-medium">Our phone</div>
      <div className="flex flex-col text-base gap-2 ">
        <div>00967737667777</div>
        <div>00251944710047</div>
        <div>00251991168125</div>
      </div>
      <div className="text-xl font-medium">Our emails</div>
      <div className="flex flex-col text-base gap-2 ">
        <div className="">abahammam@yahoo.com</div>
        <div className="">abahammam@yahoo.com</div>
      </div>
      <div className="text-xl font-medium">Our location</div>
      <div className="flex flex-col text-base gap-2 ">
        <div>00967737667777</div>
        <div>00251944710047</div>
        <div>00251991168125</div>
      </div>
      <div className="flex flex-wrap items-center gap-2">
        <div className="font-medium">Follow us</div>
        <img className="p-1 w-10 h-10" src={Facebook} alt="" />
        <img className="p-1 w-9 h-9" src={Telegram} alt="" />
        <img className="p-1 w-9 h-9" src={Tiktok} alt="" />
        <img className="p-1 w-9 h-9" src={Instagram} alt="" />
      </div>
    </div>
  );
}

export default Contact;
