// Navbar.js
import React, { useState } from "react";
import Logo from "../Logos/Dream Abpco.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className=" text-white font-Wallika">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex items-center justify-between lg:h-10 h-16">
          <div className="flex items-center">
            <div className="flex-shrink-0 text-2xl font-bold">
              <a href="">
                <img
                  src={Logo}
                  alt=""
                  className="w-14 h-14 bg-white my-5 rounded-full"
                />
              </a>
            </div>
            <div className="hidden md:block">
              {/* Add links for larger screens */}
              <a href={"/about"} className="px-3 text-[16px] font-light">
                About
              </a>
              <a href={"/contact"} className="px-3 text-[16px] font-light">
                Contact
              </a>
              <a href={"/export"} className="px-3 text-[16px] font-light">
                Export
              </a>{" "}
              <a href={"/import"} className="px-3 text-[16px] font-light">
                Import
              </a>
              {/* Add more links as needed */}
            </div>
          </div>
          <div className="md:hidden">
            {/* Hamburger menu for mobile */}
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center rounded-md focus:outline-none focus:bg-[1e1e1e]"
            >
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                {isOpen ? (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                ) : (
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                )}
              </svg>
            </button>
          </div>
          {/* Mobile menu */}
          <div className={`md:hidden ${isOpen ? "block" : "hidden"}`}>
            <div className=" mt-14 space-y-1 sm:px-3">
              {/* Mobile links */}
              <a href={"/about"} className="block px-3 pext-babase font-normal">
                About
              </a>
              <a
                href={"/contact"}
                className="block px-3 pext-babase font-normal"
              >
                Contact
              </a>
              <a
                href={"/export"}
                className="block px-3 pext-babase font-normal"
              >
                Export
              </a>
              <a
                href={"/import"}
                className="block px-3 pext-babase font-normal"
              >
                Import
              </a>
              {/* Add more links as needed */}
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
