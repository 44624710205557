import React from "react";

import Exbook from "../img/imports/bravo/Exbook.jpg";
import Pencils from "../img/imports/bravo/Pencils.jpg";
import Pens from "../img/imports/bravo/Pens.jpg";
import Post from "../img/imports/bravo/Post.jpg";
import SinglePencil from "../img/imports/bravo/SinglePencil.jpg";
import Tomatoes from "../img/imports/pureto/Tomatoes.jpg";
import Paper1 from "../img/imports/hp/Paper1.jpg";
import Paper2 from "../img/imports/hp/Paper2.png";
import Paper3 from "../img/imports/hp/Paper3.jpg";
import Paper4 from "../img/imports/hp/Paper4.png";
import Paper5 from "../img/imports/hp/Paper5.png";

function ImportDetail() {
  return (
    <div className="h-screen font-Wallika">
      <div className="text-[30px] font-medium mt-5 ml-5">Import Materials</div>
      {/* one section here */}
      <div className="p-2">
        <div className="text-[20px] font-medium text-center bg-gray-200">
          Bravo Products
        </div>
        <div className="flex flex-wrap gap-1 m-5 items-center justify-center">
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-lg" src={Exbook} alt="" />
            <div className="text-xl font-semibold text-center">Textbooks</div>
          </div>
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Pencils} alt="" />
            <div className="text-xl font-semibold text-center">Pencils</div>
          </div>
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Pens} alt="" />
            <div className="text-xl font-semibold text-center">Pens</div>
          </div>
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Post} alt="" />
            <div className="text-xl font-semibold text-center">Posts</div>
          </div>
          <div className=" border border-black p-2 m-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={SinglePencil} alt="" />
            <div className="text-xl font-semibold text-center">Single Pencil</div>
          </div>
        </div>
      </div>
      {/* the section ends here */}
      {/* one section here */}
      <div className="p-2">
        <div className="text-[20px] font-medium text-center bg-gray-200">
          HP Products
        </div>
        <div className="flex flex-wrap gap-3 m-5 items-center justify-center">
          <div className=" border border-black p-2 rounded-lg">
            <img className="w-60 h-48 rounded-lg" src={Paper1} alt="" />
            <div className="text-xl font-semibold text-center">Office </div>
          </div>
          <div className=" border border-black p-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Paper2} alt="" />
            <div className="text-xl font-semibold text-center">Recycled</div>
          </div>
          <div className=" border border-black p-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Paper3} alt="" />
            <div className="text-xl font-semibold text-center">Premium</div>
          </div>
          <div className=" border border-black p-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Paper4} alt="" />
            <div className="text-xl font-semibold text-center">Everyday</div>
          </div>
          <div className=" border border-black p-2 rounded-lg">
            <img className="w-60 h-48 rounded-md" src={Paper5} alt="" />
            <div className="text-xl font-semibold text-center">
              BusinessCopy
            </div>
          </div>
        </div>
      </div>
      {/* the section ends here */}
      {/* one section here */}
      <div className=" border">
        <div className="text-[20px] font-medium text-center bg-gray-200">
          Pureto
        </div>
        <div className="flex flex-wrap gap-3 m-5 items-center justify-center">
          <div className=" border border-black p-2 rounded-lg">
            <img className="w-60 h-48 rounded-lg" src={Tomatoes} alt="" />
            <div className="text-xl font-semibold text-center">Pureto Tomato Pastes</div>
          </div>
        </div>
      </div>
      {/* the section ends here */}
    </div>
  );
}

export default ImportDetail;
