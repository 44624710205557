import React from "react";
import HProd from "../img/imports/Bravo.png";
import Stationary from "../img/imports/Paper4.png";
import Tire from "../img/imports/Tire.png";
import Pureto from "../img/imports/Pureto.png";
import OPenLink from "../icons/openLink.png";
import { Link } from "react-router-dom";

function ImportSection() {
  return (
    <div className="h-full pb-10  flex flex-col justify-center items-center font-Wallika">
      <div className="text-[20px] font-normal mb-2 text-center">We Import</div>
      <div className="text-[16px] font-normal mb-3 text-center">
        Our Import Materials
      </div>
      <div className="flex flex-wrap gap-x-10 justify-center gap-y-5 ">
        <div>
          <div className="w-[15rem] h-[15rem] grid grid-rows-[70%_30%] rounded-xl border border-gray-300 overflow-hidden ">
            <img className="" src={HProd} alt="" />
          </div>
          <div className="text-center">
            <div className="font-normal text-[20px]">Bravo products</div>
            <div className="text-[14px] font-light m-auto">
              High Quality pens and pencils
            </div>
            <a href={"/import"}>
              <div className="font-semibold bg-[#334d63] h-8 text-white flex justify-center items-center ml-10 mr-10 rounded-lg text-sm">
                more
              </div>
              <Link to={"https://www.bravo-way.com/"}>
                <img className="w-5 ml-28" src={OPenLink} />
              </Link>
            </a>
          </div>
        </div>

        <div>
          <div className="w-[15rem] h-[15rem] grid grid-rows-[70%_30%] rounded-xl border border-gray-300 overflow-hidden gap-4">
            <img className="" src={Stationary} alt="" />
          </div>
          <div className="text-center">
            <div className="font-normal text-[20px]">HP product papers</div>
            <div className="text-[14px] font-light m-auto">
              High Quality papers of any size
            </div>
            <a href={"/import"}>
              <div className="font-semibold bg-[#334d63] h-8 text-white flex justify-center items-center ml-10 mr-10 rounded-lg text-sm">
                more
              </div>
            </a>
            <Link to={"https://www.hp.com/us-en/shop/mdp/hp-everday-paper"}>
              <img className="w-5 ml-28" src={OPenLink} />
            </Link>
          </div>
        </div>
        <div>
          <div className="w-[15rem] h-[15rem] grid grid-rows-[70%_30%] rounded-xl border border-gray-300 ">
            <img className="" src={Tire} alt="" />
          </div>
          <div className="text-center">
            <div className="font-normal text-[20px]">Tires</div>
            <div className="text-[14px] font-light m-auto">
              Different three wheel vehicle tires
            </div>
            <a href={"/import"}>
              <div className="font-semibold bg-[#334d63] h-8 text-white flex justify-center items-center ml-10 mr-10 rounded-lg text-sm">
                more
              </div>
            </a>
          </div>
        </div>
        <div>
          <div className="w-[15rem] h-[15rem] grid grid-rows-[70%_30%] rounded-xl border border-gray-300 ">
            <img className="" src={Pureto} alt="" />
          </div>
          <div className="text-center">
            <div className="font-normal text-[20px]">Beans</div>
            <div className="text-[14px] font-light m-auto">
              tasty and quality tomato paste
            </div>
            <a href={"/import"}>
              <div className="font-semibold bg-[#334d63] h-8 text-white flex justify-center items-center ml-10 mr-10 rounded-lg text-sm">
                more
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImportSection;
