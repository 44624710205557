import React from "react";
import Beans from "../img/exports/Beans.jpg";
import Oilseeds from "../img/exports/Oil seeds.jpg";
import Coffee from "../img/exports/GreenCoffeeBean.jpg";
import Herbs from "../img/exports/herbs.jpg";
import Pulses from "../img/exports/pulses.jpg";
import Spices from "../img/exports/spices.jpg";

function ExportSection() {
  return (
    <div className="h-full font-Wallika">
      <div className="text-[25px] font-normal mb-2 text-center">
        We Proudly Export
      </div>
      <div className="text-[16px] font-normal mb-3 text-center">
        Our Export Materials
      </div>
      <div className="flex flex-wrap gap-x-10 justify-center gap-y-5">
        <div className="w-72 h-72 grid grid-rows-[60%_40%] rounded-xl border border-gray-300 ">
          <div className="bg-cover bg-center rounded-xl">
            <img className="" src={Beans} alt="" />
          </div>
          <div className="bg-white text-center rounded-br-xl rounded-bl-xl p-1">
            <div className="font-normal text-[16px]">Beans</div>
            <div className="text-[14px] m-auto">Chickpea Black-eyed Black</div>
            <a href={"/export"}>
              <div className="font-semibold bg-blue-950 h-8 text-white flex justify-center items-center ml-10 mr-10 rounded-lg">
                more
              </div>
            </a>
          </div>
        </div>
        <div className="w-72 h-72 grid grid-rows-[60%_40%] rounded-xl border border-gray-300 ">
          <div className="bg-cover bg-center rounded-xl">
            <img className="" src={Oilseeds} alt="" />
          </div>
          <div className="bg-white text-center rounded-br-xl rounded-bl-xl p-1">
            <div className="font-normal text-[16px]">Oilseeds</div>
            <div className="text-[14px] m-auto">Sesame Sunflower Soybeans</div>
            <a href={"/export"}>
              <div className="font-semibold bg-blue-950 h-8 text-white flex justify-center items-center ml-10 mr-10 rounded-lg hover:text-white hover:border">
                more
              </div>
            </a>
          </div>
        </div>
        <div className="w-72 h-72 grid grid-rows-[60%_40%] rounded-xl border border-gray-300 ">
          <div className="bg-cover bg-center rounded-xl">
            <img className="" src={Herbs} alt="" />
          </div>
          <div className="bg-white text-center rounded-br-xl rounded-bl-xl p-1">
            <div className="font-normal text-[16px]">Herbs</div>
            <div className="text-[14px] m-auto">Mints, Rosemary, Basil etc</div>
            <a href={"/export"}>
              <div className="font-semibold bg-blue-950 h-8 text-white flex justify-center items-center ml-10 mr-10 rounded-lg hover:text-white hover:border">
                more
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap gap-x-10 justify-center gap-y-5 mt-10 mb-5">
        <div className="w-72 h-72 grid grid-rows-[60%_40%] rounded-xl border border-gray-300 ">
          <div className="bg-cover bg-center rounded-xl">
            <img className="" src={Spices} alt="" />
          </div>
          <div className="bg-white text-center rounded-br-xl rounded-bl-xl p-1">
            <div className="font-normal text-[16px]">Spices</div>
            <div className="text-[14px] m-auto">Thime Sage Black Pepper</div>
            <a href={"/export"}>
              <div className="font-semibold bg-blue-950 h-8 text-white flex justify-center items-center ml-10 mr-10 rounded-lg hover:text-white hover:border">
                more
              </div>
            </a>
          </div>
        </div>
        <div className="w-72 h-72 grid grid-rows-[60%_40%] rounded-xl border border-gray-300 ">
          <div className="bg-cover bg-center rounded-xl">
            <img className="" src={Pulses} alt="" />
          </div>
          <div className="bg-white text-center rounded-br-xl rounded-bl-xl p-1">
            <div className="font-normal text-[16px]">Pulses</div>
            <div className="text-[14px] m-auto">
              black, red, brown, lentils peas
            </div>
            <a href={"/export"}>
              <div className="font-semibold bg-blue-950 h-8 text-white flex justify-center items-center ml-10 mr-10 rounded-lg hover:text-white hover:border">
                more
              </div>
            </a>
          </div>
        </div>
        <div className="w-72 h-72 grid grid-rows-[60%_40%] rounded-xl border border-gray-300 ">
          <div className="bg-cover bg-center rounded-xl">
            <img className="" src={Coffee} alt="" />
          </div>

          <div className="bg-white text-center rounded-br-xl rounded-bl-xl p-1">
            <div className="font-normal text-[16px]">Coffee</div>
            <div className="text-[14px] m-auto">Quality coffee beans</div>
            <a href={"/export"}>
              <div className="font-semibold bg-blue-950 h-8 text-white flex justify-center items-center ml-10 mr-10 rounded-lg hover:text-white hover:border">
                more
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExportSection;
