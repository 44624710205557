import React, { useState, useEffect } from "react";

import Sister1 from "../img/peoples/Sister_1.jpg";
import Sister2 from "../img/peoples/Sister_2.jpg";
import Sister5 from "../img/peoples/Sister_5.jpg";
import Sister4 from "../img/peoples/Sister_4.jpg";


const images = [Sister1, Sister2, Sister4,Sister5];

const Slideshow = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);



  const nextSlide = () => {
    const index = (currentImageIndex + 1) % images.length;
    setCurrentImageIndex(index);
  };

  useEffect(() => {
    // Automatically switch to the next slide every 3000 milliseconds (3 seconds)
    const intervalId = setInterval(() => {
      nextSlide();
    }, 2000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [currentImageIndex]); // Re-run the effect when the currentImageIndex changes

  return (
    <div className="h-full m-5 lg:mb-5 flex flex-col justify-center items-center  font-Wallika ">
      <div className="">
        <img
          className="h-96 rounded-xl"
          src={images[currentImageIndex]}
          alt={`Slide ${currentImageIndex + 1}`}
        />
      </div>
    </div>
  );
};

export default Slideshow;
