import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="font-Wallika">
      <footer className="bg-black/85 text-white p-4 lg:pt-10">
        <div className="grid lg:grid-cols-3 grid-cols-1 text-[14px] font-light gap-3">
          <div className="flex flex-wrap items-center gap-2">
            <div className="font-medium text-2xl">Dream Abpco</div>
          </div>
          <div className="flex lg:flex-col lg:items-left gap-2 text-[16px]">
            <div className="">Email us</div>
            <a className="text-blue-200 font-medium">abahammam@yahoo.com</a>
            <a
              href="https://abahammam@dreamapbco.com"
              className="text-blue-200"
            >
              abahammam@dreamapbco.com
            </a>
          </div>
          <div className="flex flex-col">
            <div className="text-[18px]">Call Us</div>
            <div className="flex flex-col gap-2 text-[16px]">
              <div>00967737667777</div>
              <div>00251944710047</div>
            </div>
          </div>
          <div className="flex flex-row gap-5 my-4 items-center text-blue-200 font-light">
            <div className="text-[20px] text-white">Dream</div>
            <a href={"about"} className="text-[16px]">
              about
            </a>
            <a href={"/contact"} className="text-[16px]">
              contact
            </a>
            <a href={"terms"} className="text-[16px]">
              Terms
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
