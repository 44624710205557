import React from "react";
import Flash from "../icons/flash.png";
import Security from "../icons/security.png";
import HandShake from "../icons/handshake.png";
import { useRef, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

function ChooseUS() {
  const compRef = useRef(null);

  useEffect(() => {
    if (compRef.current) {
      gsap.to(compRef.current, {
        y: -100,
        duration: 1.4,
        delay: 0.4,
        scrollTrigger: {
          trigger: compRef.current,
          start: "top center", // Adjust the start position as needed
          end: "bottom center", // Adjust the end position as needed
          scrub: true, // Smooth animation
        },
      });
    }
  }, []);

  return (
    <div id="chooseus" ref={compRef}>
      <div className="text-black m-10 font-Wallika font-normal">
        <div className="text-[30px] mt-3 mb-3 text-center">
          Why US
          <div className="text-[18px] font-light">
            We believe that working together as teamwork and doing thing right
            from the first time!
          </div>
        </div>
        <div className="w-full flex flex-row justify-center items-center">
          <div className="flex justify-center gap-y-5 md:gap-x-10 lg:gap-x-10 lg:flex-row md:flex-row flex-col">
            <div className="h-[20rem] w-[16rem] border border-gray-300 grid grid-row rounded-xl bg-blue-50/50 p-2 chooseus-flash">
              <div className="w-10 h-10 border border-gray-300 rounded-full mt-2 ml-2 p-1">
                <img src={Flash} alt="" />
              </div>

              <div className="text-[18px] font-medium text-center">
                Dedication and Experience
              </div>
              <div className="text-[14px] text-center">
                We have a dedicated and experienced team, who will work with you
                closely to understand your needs and goals.
              </div>
            </div>

            <div className="h-[20rem] w-[16rem] border border-gray-300 grid grid-row rounded-xl bg-blue-50/50 p-2">
              <div className="w-10 h-10 border border-gray-300 rounded-full mt-2 ml-2 p-1 ">
                <img src={Security} alt="" />
              </div>

              <div className="text-[18px] font-medium text-center">
                Commitment and Sustainability
              </div>
              <div className="text-[14px] text-center">
                We have a commitment to sustainability, with a focus on
                environmental and social responsibility
              </div>
            </div>

            <div className="h-[20rem] w-[16rem] border border-gray-300 grid grid-row rounded-xl bg-blue-50/50 p-2">
              <div className="w-10 h-10 border border-gray-300 rounded-full mt-2 ml-2 p-1">
                <img src={HandShake} alt="" />
              </div>

              <div className="text-[18px] font-medium text-center">
                Trust and Win
              </div>
              <div className="text-[14px] text-center">
                Our deal with our customer or our supplies is a Win – WIN
                situation.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChooseUS;
